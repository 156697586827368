import styled from 'styled-components';

export const StyledPost = styled.div`
  padding: 0 20px;

  .post-topic {
    margin: 20px 0;
    padding-bottom: 20px;

    border-bottom: 1px solid ${({ theme }) => theme.colors.gray};

    & > h1 {
      font-size: ${({ theme }) => theme.fontSizes.h1.m};
      font-weight: ${({ theme }) => theme.fontWeights[4]};
      padding: 20px 0;
    }

    &_author-link {
      text-decoration: none;
      font-weight: ${({ theme }) => theme.fontWeights[4]};
      color: ${({ theme }) => theme.colors.black};

      &:hover {
        color: ${({ theme }) => theme.colors.tycoonMain};
      }
    }

    &_time {
      color: ${({ theme }) => theme.colors.darkGray};
    }
  }

  .post-layout {
    display: flex;
    flex-wrap: wrap;

    & .post-layout_grid-item {
      box-sizing: border-box;

      &:last-of-type {
        margin-bottom: 20px;
      }

      &__main {
        flex-basis: 70%;
        padding-right: 20px;

        & img {
          display: block;
          width: 100%;
          margin-bottom: 20px;
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
          flex-basis: 50%;
          padding-right: 0;
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
          flex-basis: 100%;
          margin-bottom: 20px;
        }

        &_content {
          h1 {
            font-size: ${({ theme }) => theme.fontSizes.h1.l};
            font-weight: ${({ theme }) => theme.fontWeights[4]}
          }

          h2 {
            font-size: ${({ theme }) => theme.fontSizes.h2.l};
            font-weight: ${({ theme }) => theme.fontWeights[4]};
          }

          h3 {
            font-size: ${({ theme }) => theme.fontSizes.h3.l};
            font-weight: ${({ theme }) => theme.fontWeights[4]};
          }

          ol {
            list-style: decimal outside;
            padding-left: 1em;
          }

          ul {
            list-style: disc outside;
            padding-left: 1em;
          }

          .ql-align-center {
            text-align: center;
          }

          .ql-align-right {
            text-align: right;
          }

          strong {
            font-weight: ${({ theme }) => theme.fontWeights[5]};
          }

          em {
            font-style: italic;
          }

          img {
            display: inline-block;
            width: 80%;
          }

          blockquote {
            padding: 1em;
            font-style: italic;
            background: rgba(0, 0, 0, 0.2);
            margin: 1em 0;
          }
        }
      }
      
      &__side {
        padding-left: 20px;
        flex-basis: 30%;
        font-size: ${({ theme }) => theme.fontSizes.m};

        & > :first-of-type, & > :last-of-type {
          font-size: ${({ theme }) => theme.fontSizes.s};
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[0]}) {
          flex-basis: 50%;

          & > :first-of-type, & > :last-of-type {
            font-size: ${({ theme }) => theme.fontSizes.m};
          }
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
          flex-basis: 100%;
          display: flex;
          flex-wrap: wrap;
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[1]}) {
          flex-basis: 100%;
          display: flex;
          flex-wrap: wrap;

          & section {
            &:first-of-type {
              flex-basis: 70%;
              box-sizing: border-box;
              padding-right: 20px;

              & article {
                padding-left: 0;
              }

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
                flex-basis: 100%;
                padding-right: 0;
                padding-left: 20px;
              }

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[3]}) {
                font-size: ${({ theme }) => theme.fontSizes.s};
              }

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[5]}) {
                font-size: ${({ theme }) => theme.fontSizes.xs};
              }
            }

            &:nth-of-type(2) {
              flex-basis: 30%;

              font-size: ${({ theme }) => theme.fontSizes.s};
              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
                flex-basis: 100%;
                display: flex;
                flex-wrap: wrap;

                & article {
                  flex-basis: 50%;
                }
              }

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[4]}) {
                & article {
                  flex-basis: 100%;
                }
              }
            }

            &:last-of-type {
              flex-basis: 100%;

              & article {
                padding-left: 0;
              }

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
                & article {
                  padding-left: 20px;
                }
              }

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[3]}) {
                font-size: ${({ theme }) => theme.fontSizes.s};
              }

              @media only screen and (max-width: ${({ theme }) => theme.breakpoints[5]}) {
                font-size: ${({ theme }) => theme.fontSizes.xs};
              }
            }
          }
        }

        @media only screen and (max-width: ${({ theme }) => theme.breakpoints[2]}) {
          padding-left: 0;
        }
      }
    }
  }
`;
