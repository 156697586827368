import React from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';

import { StyledBreadcrumbs } from './breadcrumbs.styled';

function Breadcrumbs ({ steps }) {
  return (
    <StyledBreadcrumbs>
      {steps.map((step, i) => (
        <li className="breadcrumb-item" key={step.url}>
          {i !== 0 && <FaChevronRight className="breadcrumb-item_marker" />}
          <a href={step.url}>
            {step.title}
          </a>
        </li>
      ))}
    </StyledBreadcrumbs>
  );
}

Breadcrumbs.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.exact({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

Breadcrumbs.defaultProps = {
  steps: [{ title: '', url: '' }]
};

export default Breadcrumbs;
