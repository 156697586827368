import React from 'react';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import { Query } from 'react-apollo';

import { ALL_PUBLISHED_POSTS_BY_CATEGORY } from '../../graphql/queries';
import Breadcrumbs from '../../common/components/Breadcrumbs';
import { getFormattedTime, getImageUrl } from '../../common/helpers';
import Pagination from '../Pagination';
import PostsSection from '../PostsSection';
import { StyledPost } from './post.styled';

const Post = ({ post_id, title, text, date, author, category, videoPosts, popularPosts, tycoonPosts, authorId }) => (
  <StyledPost>
    <div className="post-topic">
      <Breadcrumbs steps={[{ title: 'Home', url: '/blog' }, { title: category, url: `/categories/${kebabCase(category)}` }]} />
      <h1>{title}</h1>
      By <Link to={`/authors/${authorId}`} className="post-topic_author-link">{`${author.user_name} ${author.user_surname}`}</Link><i> &#8226; </i><time className="post-topic_time" dateTime={new Date(+date).toISOString()}>{getFormattedTime(+date)}</time>
    </div>
    <div className="post-layout">
      <div className="post-layout_grid-item post-layout_grid-item__main">
        <img src={getImageUrl({ post_id })} alt={title} />
        <div dangerouslySetInnerHTML={{ __html: text }} className="post-layout_grid-item post-layout_grid-item__main_content" />
      </div>
      <div className="post-layout_grid-item post-layout_grid-item__side">
        <PostsSection posts={popularPosts} postProps={{ direction: 'row' }} />
        <PostsSection sectionTitle="Video" posts={videoPosts} postProps={{
          direction: 'column',
          type: 'videoLink'
        }} />
        <PostsSection sectionTitle="Tycoon News" posts={tycoonPosts} postProps={{ direction: 'row' }} />
      </div>
      <Query query={ALL_PUBLISHED_POSTS_BY_CATEGORY} variables={{ sortBy: 'publish_date', order: 'DESC', limit: 8, from: 0, category }}>
      {
        ({ loading, error, data, refetch }) => {
          return (
            <Pagination
              posts={data ? data.allPublishedPostsByCategory : []}
              handlePagination={() => refetch({ sortBy: 'publish_date', order: 'DESC', limit: data.allPublishedPostsByCategory.length * 2, from: 0 })}
              className="post-layout_grid-item"
              loading={loading}
            />
          );
        }
      }
    </Query>
    </div>
  </StyledPost>
);

export default Post;
