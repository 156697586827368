import styled from 'styled-components';

export const StyledBreadcrumbs = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.m};
  height: 1.2em;
  line-height: font-size: ${({ theme }) => theme.lineHeights.title};

  .breadcrumb-item {
    &_marker {
      color: ${({ theme }) => theme.colors.tycoonMain};
      font-size: .8em;
      margin-left: .5em;
    }

    & a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
      &:hover {
        color: ${({ theme }) => theme.colors.tycoonMain};
      }
    }

    &:last-of-type {
      & a {
        font-size: ${({ theme }) => theme.fontSizes.l};
        font-weight: ${({ theme }) => theme.fontWeights[4]};
        padding-left: .5em;
      }
    }
  }
`;
