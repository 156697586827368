import React from 'react';
import { graphql } from 'gatsby';

import { sliceText } from '../common/helpers';
import Layout from '../containers/Layout';
import Post from '../containers/Post';

const PostPage = ({ data, location }) => {
  return (
    <Layout title={data.posts.post.title} description={sliceText(data.posts.post.post_text, 80)} location={location.href}>
      <Post
        post_id={data.posts.post.post_id}
        title={data.posts.post.title}
        text={data.posts.post.post_text}
        date={data.posts.post.publish_date}
        author={data.posts.post.author}
        authorId={data.posts.post.author_id}
        category={data.posts.post.category}
        videoPosts={data.posts.videoPosts}
        popularPosts={data.posts.allPublishedPosts}
        tycoonPosts={data.posts.tycoonPosts}
      />
    </Layout>
  );
};

export const query = graphql`
  query PostData($post_id: ID) {
    posts {
      post(post_id: $post_id) {
        post_id
        post_text
        title
        category
        publish_date
        author_id
        author {
          user_name
          user_surname
        }        
      }

      videoPosts(sortBy: "post_date", order: "DESC", from: 0, limit: 3) {
        post_date
        title
        category
        video_post_id
        video_url
      }

      allPublishedPosts(sortBy: "popularity_count", order: "DESC", from: 0, limit: 3) {
        post_id
        title
        category
        publish_date
      }

      tycoonPosts(sortBy: "publish_date", order: "DESC", from: 0, limit: 3) {
        post_id
        title
        category
        publish_date
      }
    }
  }
`;

export default PostPage;
